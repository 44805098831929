<template>
  <article class="message is-warning">
    <div class="message-header">
      <span class="has-text-base"><span
        class="m-r-2x has-text-base"
        style="font-weight: 900;font-size: 1.25em;">!</span>{{ $t('components.BookingPolicyPriceChange.title') }}</span>
    </div>
    <div
      class="message-body"
      style="padding: 0;">
      <div class="price-change">
        <div class="price-change-left">
          <div class="has-text-grey-light is-size-5 has-text-weight-bold">
            {{ selectedPckg.supplierDescription | truncate }}
          </div>
          <div
            v-if="$isFoodCodeValid(selectedPckg.foodCode)"
            class="has-text-grey-light is-size-7 m-b-3x">
            - {{ selectedPckg.foodCode | foodCode }}
          </div>
          <div class="has-text-grey-light has-text-weight-bold">
            {{ selectedPckgAdjustedDisplayRate | currencyObj({factionDigits:0}) }}
          </div>
          <div class="has-text-grey-light is-size-7">
            {{ nights|nightsAllRoom($store.state.inNightlyAvgRate,$store.state.searchOne.roomCount) }}
          </div>
          <!--          <div class="has-text-grey-light is-size-7">{{$t('components.BookingPolicyPopup.included-taxes')}} {{ selectedPckg.adjustedDisplayRate | taxesAndFees | currencyObj }} <span-->
          <!--              v-html="selectedPckg.adjustedDisplayRate.taxesConfident === 0 ? $t('components.BookingPolicyPopup.estimated') : ''"/></div>-->
        </div>
        <div class="price-change-center has-text-success">
          <IconBase
            width="26"
            height="16">
            <IconArrowRightLong />
          </IconBase>
        </div>
        <div class="price-change-right">
          <div class="has-text-primary is-size-5 has-text-weight-bold">
            {{ supplierDescription | truncate }}
          </div>
          <div
            v-if="$isFoodCodeValid(foodCode)"
            class="has-text-base is-size-7 m-b-3x">
            - {{ foodCode | foodCode }}
          </div>
          <div class="has-text-primary has-text-weight-bold">
            {{ adjustedDisplayRateComputed | currencyObj({factionDigits:0}) }}
          </div>
          <div class="has-text-base is-size-7">
            {{ nights|nightsAllRoom($store.state.inNightlyAvgRate,$store.state.searchOne.roomCount) }}
          </div>
          <!--          <div class="has-text&#45;&#45;base is-size-7">{{$t('components.BookingPolicyPopup.included-taxes')}} {{ adjustedDisplayRate | taxesAndFees | currencyObj }} <span-->
          <!--              v-html="adjustedDisplayRate.taxesConfident === 0 ? $t('components.BookingPolicyPopup.estimated') : ''"/></div>-->
        </div>
      </div>
      <div v-if="hotelFees">
        {{ $t('components.BookingPolicyPriceChange.due') }} {{ hotelFees }}
      </div>
    </div>
  </article>
</template>

<script>
/* eslint-disable vue/require-prop-types */

import IconArrowRightLong from './icons/IconArrowRightLong'

export default {
  name: 'BookingPolicyPriceChange',
  components: { IconArrowRightLong },
  props: [
    'taxAndFees',
    'agentAdjustedDisplayRate',
    'agentUnitAdjustedDisplayRate',
    'cancellationPolicy',
    'supplierDescription',
    'foodCode',
    'hotelFees',
    'selectedPckg',
    'nights'
  ],
  computed: {
    selectedPckgAdjustedDisplayRate () {
      return this.$store.state.inNightlyAvgRate ? this.selectedPckg.agentUnitAdjustedDisplayRate : this.selectedPckg.agentAdjustedDisplayRate
    },
    adjustedDisplayRateComputed () {
      return this.$store.state.inNightlyAvgRate ? this.agentUnitAdjustedDisplayRate : this.agentAdjustedDisplayRate
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .message.is-warning .message-header {
    background: #FFEFD6;
    border: 1px solid #F16A00;
    border-bottom: none;
  }

  .price-change {
    color: $text;
    border: 1px solid #F16A00;
    background-color: $white;
    display: flex;

    &-center {
      flex: 0 0 26px;
      width: 26px;
      padding: 20px 0;
    }

    &-left, &-right {
      flex: 1 1 auto;
      padding: 20px;
    }
  }
</style>
