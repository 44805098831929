<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.0184 3.55521C10.0184 5.51869 8.42666 7.11041 6.46318 7.11041C4.49969 7.11041 2.90797 5.51869 2.90797 3.55521C2.90797 1.59172 4.49969 0 6.46318 0C8.42666 0 10.0184 1.59172 10.0184 3.55521ZM4.09658 26.1086L2.00741 17.2394C1.11245 17.0863 0.414232 16.3374 0.351226 15.4027L0.00462052 10.2607C-0.0732277 9.10581 0.84258 8.12619 2.00009 8.12619H3.64951H9.32118H10.9264C12.0839 8.12619 12.9998 9.10581 12.9219 10.2607L12.5753 15.4027C12.5134 16.3212 11.838 17.0603 10.9653 17.231L8.8741 26.1086C8.61304 27.2168 7.62396 28 6.48534 28C5.34673 28 4.35765 27.2168 4.09658 26.1086Z" />
</template>

<script>
export default {
  name: 'IconPerson'
}
</script>

<style scoped>

</style>
