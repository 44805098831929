import { render, staticRenderFns } from "./IconArrowRightLong.vue?vue&type=template&id=9f1a8d08&scoped=true&"
import script from "./IconArrowRightLong.vue?vue&type=script&lang=js&"
export * from "./IconArrowRightLong.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f1a8d08",
  null
  
)

export default component.exports