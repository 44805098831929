<template>
  <div
    v-if="adult||children"
    class="occupancy-icons">
    <IconBase
      v-for="i in adult"
      :key="`adult${i}`"
      width="13"
      height="28"
      icon-name="adult"
      class="icon-adult">
      <IconPerson />
    </IconBase>
    <IconBase
      v-for="i in children"
      :key="`children${i}`"
      width="13"
      height="28"
      icon-name="children"
      class="icon-children">
      <IconPerson />
    </IconBase>
  </div>
</template>

<script>
import IconPerson from './icons/IconPerson'

export default {
  name: 'Occupancy',
  components: { IconPerson },
  props: {
    adult: {
      type: Number,
      default: 0
    },
    children: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/bulma-variables";

  .occupancy-icons {
    color: $occupancy-color;
  }

  .icon-adult {
    vertical-align: bottom;
    margin-right: 2px;
  }

  .icon-children {
    width: 9px;
    height: 19px;
    margin-right: 2px;
    vertical-align: bottom;
  }

</style>
