<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6 0L7.5 1.5L3 6L7.5 10.5L6 12L0 6L6 0Z" />
</template>

<script>
export default {
  name: 'IconArrowLeft'
}
</script>
