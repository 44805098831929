<template>
  <div class="card-package">
    <div class="card-package-header">
      <div class="is-size-5 has-text-primary has-text-weight-bold">
        {{ roomCount }} x {{ packageDescription | truncate }}
      </div>
    </div>
    <div class="card-package-content-wrapper">
      <div class="card-package-content">
        <div
          v-if="heroImage.url"
          class="attr">
          <div class="attr-key" />
          <div class="attr-val">
            <img
              :src="heroImage.url"
              class="pack-img">
          </div>
        </div>
        <div class="attr">
          <div
            class="attr-key"
            style="padding-left: 15px">
            {{ $t('components.Packages.label-title') }}
          </div>
          <div class="attr-val">
            <PackagesBenefits :pckg="pckg" />
          </div>
        </div>
        <div class="attr">
          <div class="attr-key">
            {{ $t('components.Packages.Occupancy') }}
          </div>
          <div class="attr-val">
            <Occupancy
              :adult="adultCount"
              :children="childrenCount" />
          </div>
        </div>
      </div>
      <!-- second phase -->
      <!-- <div class="card-package-content-has-sub">
        <div
          v-for="(refundable,i) in ['Refundable','Non-refundable']"
          :key="refundable"
          class="card-package-sub">
          <div class="attr">
            <div
              v-if="i===0"
              class="attr-key">{{ $t('components.Packages.price-title') }}</div>
            <div class="attr-val has-text-right">
              <div class="has-text-left">{{ $t(`components.Packages.${refundable}`) }}</div>
              <AdjustmentBadge
                :adjustment-pct="pckg.initialAdjustmentPct"
                class="is-size-7"/>

              <div
                v-if="pckg.initialAdjustmentPct < -0.01"
                class="has-text-line-through has-text-base is-size-6 has-text-weight-bold is-marginless">
                {{ displayRate | currencyObj({ factionDigits: 0 }) }}
              </div>

              <div class="is-4 subtitle has-text-weight-semibold has-text-primary is-marginless"><span class="is-size-7 has-text-grey-light has-text-weight-normal">{{ $t('components.Property.nightly-avg') }}</span> {{ adjustedDisplayRate | currencyObj({ factionDigits: 0 }) }}</div>
              <PriceAudit
                v-if="pckg.raw"
                :raw="pckg.raw"/>
            </div>
          </div>
          <div class="attr">
            <div
              v-if="i===0"
              class="attr-key  has-text-right">
              <div
                style="width: 130px;"
                class="is-inline-block has-text-left">{{ $t('components.Packages.book-title') }}</div>
            </div>
            <div class="attr-val has-text-right">
              <slot
                v-if="i===0"
                name="call-to-action"/>
              <slot
                v-else
                name="call-to-action-noRefundAble"/>
            </div>
          </div>
        </div>
      </div> -->

      <div class="card-package-content-has-sub">
        <div class="card-package-sub">
          <div class="attr">
            <div class="attr-key has-text-right">
              Price
            </div>
            <div class="attr-val has-text-right">
              <AdjustmentBadge
                :adjustment-pct="pckg.agentInitialAdjustmentPct"
                class="is-size-7" />

              <div
                v-if="pckg.agentInitialAdjustmentPct < -0.01"
                class="has-text-line-through has-text-base is-size-6 has-text-weight-bold is-marginless">
                {{ displayRate | currencyObj({factionDigits:0}) }}
              </div>

              <div class="is-4 subtitle has-text-weight-semibold has-text-primary is-marginless">
                <el-tooltip
                  placement="bottom"
                  style="padding-right:5px;">
                  <div slot="content">
                    Note: These rates include {{ getAgentMinMarkupInPct(pckg) }}%<br>
                    markup as set up by your account<br>
                    admin.
                  </div>
                  <i
                    class="el-icon-info has-text-secondary"
                    style="font-size: 0.73em;" />
                </el-tooltip>
                <span
                  class="is-size-7 has-text-grey-light has-text-weight-normal"
                  style="vertical-align: middle;">(tax Incl.)</span>
                {{ adjustedDisplayRate | currencyObj({factionDigits:0}) }}
              </div>
              <span class="has-text-grey-light is-size-7">
                {{ nights|nightsAllRoom($store.state.inNightlyAvgRate,$store.state.searchOne.roomCount) }}
              </span>
              <div class="is-size-7 has-text-grey-light has-text-weight-normal">
                Agent earning: {{ pckg.agentMarkupAmount | currencyObj }}
              </div>
              <PriceAudit
                v-if="pckg.raw"
                :raw="pckg.raw" />
            </div>
          </div>
          <div class="attr">
            <div class="attr-key  has-text-right">
              <!--              <div-->
              <!--                style="width: 130px;"-->
              <!--                class="is-inline-block has-text-left">{{ $t('components.Packages.book-title') }}</div>-->
            </div>
            <div class="attr-val has-text-right">
              <slot name="call-to-action" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdjustmentBadge from './AdjustmentBadge'
import PriceAudit from './PriceAudit'
import mixPackages from '../mixins/packages'
import PackagesBenefits from './PackagesBenefits'
import Occupancy from './Occupancy'

export default {
  name: 'Packages',
  components: {
    Occupancy,
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PriceAudit.name]: PriceAudit,
    PackagesBenefits
  },
  mixins: [mixPackages],
  props: {
    pckg: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    adultCount: {
      type: Number,
      required: true,
      default: 0
    },
    childrenCount: {
      type: Number,
      required: true,
      default: 0
    },
    nights: {
      type: Number,
      default: undefined
    },
    roomCount: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      // move to vuex store
      // inNightlyAvgRate: false // when it's false ,the price is all nights all rooms.  So when all night per room is displayed, do not switch it to false.
    }
  },
  computed: {
    nonRefundable () {
      return this.pckg.nonRefundable == null
    },
    marketRates () {
      return this.$store.state.inNightlyAvgRate ? this.pckg.unitMarketRates : this.pckg.marketRates
    },
    displayRate () {
      return this.$store.state.inNightlyAvgRate ? this.pckg.agentUnitDisplayRate : this.pckg.agentDisplayRate
    },
    adjustedDisplayRate () {
      return this.$store.state.inNightlyAvgRate ? this.pckg.agentUnitAdjustedDisplayRate : this.pckg.agentAdjustedDisplayRate
    },
    packageDescription () {
      return this.titleCase(this.pckg.supplierDescription || this.pckg.description)
    }
  },
  methods: {
    titleCase (str) {
      let splitStr = str.toLowerCase().split(' ')
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      return splitStr.join(' ')
    },
    getAgentMinMarkupInPct (pkg) {
      if (pkg && pkg.agentMinMarkupInPct) return pkg.agentMinMarkupInPct
      return 0
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .card {
    &-package {
      border: $card-border;
      border-radius: $border-radius;
      background-color: $white;

      &-header {
        display: flex;
        padding: 8px 15px;
        align-items: center;
      }

      &-content-wrapper {
        display: flex;
      }

      &-content {
        display: flex;
        flex: 0 0 50%;

        .attr:last-child {
          flex: 0 0 120px;
        }
      }

      &-content-has-sub {
        flex: 0 0 50%;
        display: block;
      }

      &-sub {
        display: flex;

        &:not(:last-child) {
          border-bottom: $card-border;
        }
      }

      .attr {
        flex: 1 1 auto;
        @include mobile {
          flex: 1 1 auto;
        }
      }

      .attr-action {
        text-align: right;
      }

      .attr-key {
        padding: $bleed $bleed*2;
        background-color: $tab-head-bg;
        height: 32px;
        overflow: hidden;
        color: $white;
        font-weight: bold;
      }

      .attr-val {
        padding: $bleed*3 $bleed*3;
      }

    }
  }

  .pack-img {
    width: 150px;
  }
</style>
