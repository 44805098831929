export default {
  computed: {
    images () {
      const images = this.pckg.images.map((img) => {
        let sUrl
        if (!('s' in img.links)) {
          sUrl = img.links.l.href
        }
        return {
          s: { url: sUrl },
          l: { url: img.links.l.href }
        }
      })
      // return [
      //   {
      //     s: { url: 'https://d1rlkd47phdpvf.cloudfront.net/8Ycj/1_500.jpg' },
      //     l: { url: 'https://d1rlkd47phdpvf.cloudfront.net/8Ycj/1_500.jpg' }
      //   }
      // ]
      return images
    },
    heroImage () {
      let heroImage = {}
      for (let i = 0; i < this.pckg.images.length; i++) {
        if (this.pckg.images[i].hero_image === true) {
          heroImage = { url: this.pckg.images[i].links.l.href }
          break
        }
      }
      return heroImage
    }
  }
}
