<template>
  <ul class="list-none m-0 p-0">
    <li v-if="pckg.foodCode">
      <i class="ap-icon-check has-text-success m-r" />{{ pckg.foodCode | foodCode }}
    </li>
    <li v-if="!pckg.nonRefundable">
      <i class="ap-icon-check has-text-success m-r" />{{ $t('components.PackageProperty.free-cancellation') }}
    </li>
    <li
      v-if="pckg.isPayLater"
      class="mb-0 tablet:mb-[10px]">
      <i class="ap-icon-check has-text-success m-r" />{{ $t('components.PackageProperty.is-pay-later') }}
    </li>
    <li
      v-if="pckg.rateType === 'pay_at_hotel'"
      class="mb-0 tablet:mb-[10px]">
      <i class="ap-icon-check has-text-success m-r" />{{ $t('components.PackageProperty.pay-at-hotel') }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PackagesBenefits',
  props: {
    pckg: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
