<template>
  <PpModal
    v-model="visibility"
    :loading-message="$t('components.BookingPolicyPopup.checking')"
    :is-loading="isLoading == 1"
    class="booking-policy-modal">
    <PpCard
      v-if="bookingPolicy.cancellationPolicy"
      class="box has-border is-flex">
      <div class="list is-gapless">
        <div class="list-content">
          <h4 class="title is-4 has-text-secondary">
            {{ $t('components.BookingPolicyPopup.title') }}
          </h4>
          <BookingPolicyPriceChange
            v-if="bookingPolicy.newPackage"
            v-bind="bookingPolicy"
            :nights="nights"
            :selected-pckg="selectedPckg" />
          <div
            v-else
            class="booking-policy-price-base m-b-5x">
            <div class="is-pulled-left">
              <div class="is-siz-5 has-text-primary has-text-weight-bold">
                {{ bookingPolicy.supplierDescription | truncate }}
              </div>
              <div
                v-if="$isFoodCodeValid(bookingPolicy.foodCode)"
                class="is-size-7">
                - {{ bookingPolicy.foodCode|foodCode }}
              </div>
            </div>
            <div class="is-pulled-right has-text-right">
              <div class="is-siz-5 has-text-primary has-text-weight-bold">
                {{ adjustedDisplayRateComputed | currencyObj({factionDigits:0}) }}
              </div>
              <div class="is-size-7">
                {{ nights|nightsAllRoom($store.state.inNightlyAvgRate,$store.state.searchOne.roomCount) }}
              </div>
            </div>
            <div class="is-clearfix" />
            <!--            <div class="has-text-right"><p class="is-size-7">-->
            <!--              {{$t('components.BookingPolicyPopup.included-taxes')}} {{ bookingPolicy.adjustedDisplayRate | taxesAndFees | currencyObj }} <span-->
            <!--                v-html="bookingPolicy.adjustedDisplayRate.taxesConfident === 0 ? $t('components.BookingPolicyPopup.estimated') : ''"/>.-->
            <!--            </p></div>-->
          </div>
          <!--<div
              class="content  m-b-5x"
              v-html="remarks" />-->
          <CancellationPenalty
            :free-cancellation-before="bookingPolicy.cancellationPolicy.freeCancellationBefore" />

          <article
            v-if="(!selectedPckg.supplierId || selectedPckg.supplierId === null) && canHold"
            class="message is-success">
            <div class="message-body">
              <p class="has-text-base">
                {{ $t('available-hold') }}
              </p>
            </div>
          </article>
        </div>
      </div>
      <template
        v-if="bookingPolicy.cancellationPolicy"
        slot="foot">
        <div class="m-b-8x" />
        <div class="list is-gapless">
          <div class="list-content has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px"
              :disabled="disableReserveBtn"
              @click="handleClose">
              {{ $t('components.BookingPolicyPopup.close') }}
            </el-button>
            <el-button
              v-if="(!selectedPckg.supplierId || selectedPckg.supplierId === null) && canHold"
              class="button-secondary"
              :disabled="disableReserveBtn"
              @click="handleNextStep(nextStep,'payLater')">
              {{ $t('hold-button') }}
            </el-button>
            <el-button
              v-if="isPayAtHotel"
              class="button-secondary"
              style="min-width: 155px"
              :disabled="disableReserveBtn"
              @click="handleNextStep(nextStep)">
              {{ $t('components.BookingPolicyPopup.proceed') }}
            </el-button>
            <el-button
              v-if="!isPayAtHotel"
              class="button-secondary"
              style="min-width: 155px"
              :disabled="disableReserveBtn"
              @click="handleNextStep(nextStep)">
              {{ $t('components.BookingPolicyPopup.paynow') }}
            </el-button>
          </div>
        </div>
      </template>
    </PpCard>
    <PpCard
      v-else
      class="box has-border is-flex">
      <div class="list is-gapless">
        <div class="list-content">
          <h4
            v-if="isLoading == 0"
            class="title is-4  has-text-secondary">
            {{ $t('components.BookingPolicyPopup.not-found') }}
          </h4>
          <p>
            {{ $t('components.BookingPolicyPopup.not-found-desc') }}
          </p>
          <h4
            v-if="isLoading == 2"
            class="title is-4  has-text-secondary">
            {{ $t('components.BookingPolicyPopup.network-problem') }}
          </h4>
        </div>
      </div>
      <template slot="foot">
        <div class="m-b-8x" />
        <div class="list is-gapless">
          <div class="list-content" />
          <div class="list-action">
            <el-button
              class="button-secondary m-t-3x"
              style="min-width: 155px;"
              @click="$emit('submit-request')">
              {{ $t('components.BookingPolicyPopup.send-request') }}
            </el-button>
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="handleClose">
              {{ $t('components.BookingPolicyPopup.close') }}
            </el-button>
          </div>
        </div>
      </template>
    </PpCard>
  </PpModal>
</template>

<script>

import CancellationPenalty from '../components/CancellationPenalty'
import BookingPolicyPriceChange from './BookingPolicyPriceChange'
import { mapState } from 'vuex'
import unescape from 'lodash/unescape'
import { poll } from '@/service/search'

export default {
  name: 'BookingPolicyPopup',
  components: {
    BookingPolicyPriceChange,
    [CancellationPenalty.name]: CancellationPenalty
  },
  props: {
    selectedPckg: {
      type: Object,
      required: true
    },
    searchId: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    searchObject: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    refundAble: {
      type: Boolean,
      default: false
    },
    locationQuery: {
      type: String,
      default: undefined
    },
    regionId: {
      type: String,
      default: undefined
    },
    sourceMarket: {
      type: String,
      default: undefined
    },
    nights: {
      type: Number,
      default: undefined
    },
    disableReserveBtn: {
      type: Boolean,
      default: false
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'hold-button': 'Hold & pay Later',
        'available-hold': 'You can hold this booking (guarantee reservation and rate, pay before cancellation deadline above), or you can fully confirm and pay now.'
      }
    }
  },
  data () {
    return {
      isLoading: 0,
      visibility: false,
      cancellationPolicy: undefined
    }
  },
  computed: {
    reserveCallToActionLabel () {
      const nights = this.$store.state.meta.nights > 1 ? `${this.$store.state.nights} ${this.$t('components.BookingPolicyPopup.nights')}` : `1 ${this.$t('components.BookingPolicyPopup.night')}`
      const rooms = this.$store.state.meta.roomCount > 1 ? `, ${this.$store.state.meta.roomCount} ${this.$t('components.BookingPolicyPopup.rooms')}` : ''
      // Reserve 1 night for
      // Reserve 1 night, 2 rooms for
      // Reserve 2 nights for
      // Reserve 2 nights, 2 rooms for
      return this.$t('components.BookingPolicyPopup.reserve', {
        nights,
        rooms
      })
    },
    ...mapState('bookingPolicy', [
      'bookingPolicy',
      'nextStep',
      'retrying'
    ]),
    adjustedDisplayRateComputed () {
      return this.$store.state.inNightlyAvgRate ? this.bookingPolicy.agentUnitAdjustedDisplayRate : this.bookingPolicy.agentAdjustedDisplayRate
    },
    remarks () {
      return this.bookingPolicy.cancellationPolicy ? unescape(this.bookingPolicy.cancellationPolicy.remarks.replace(/\snull/g, '')) : ''
    },
    canHold () {
      return this.bookingPolicy.isPayLater
    },
    isPayAtHotel () {
      return !!this.selectedPckg.supplierId
    }
  },
  created () {
    this.handleSelectedPackage(this.selectedPckg)
    this.$nextTick(function () {
      this.visibility = true
    })
  },
  methods: {
    handleClose () {
      this.visibility = false
      this.$emit('close')

      if (!this.bookingPolicy.cancellationPolicy && this.isLoading === 0) {
        // re-fetch hotel package
        this.$emit('fetchHotelPackages')
      }
    },
    handleNextStep (nextStep, payType = 'creditcard') { // payType: online / hold
      payType = this.selectedPckg.supplierId ? 'pay_at_hotel' : payType
      this.$emit('next', { ...nextStep, payType })
    },
    handleSelectedPackage (pckg) {
      this.isLoading = 1
      const body = {
        locationQuery: this.locationQuery || this.$route.query.locationQuery || this.property.name,
        regionId: this.regionId || this.$route.query.regionId,
        property: {
          name: this.property.name
        },
        search: this.searchObject,
        token: pckg.token,
        adjustedDisplayRate: pckg.adjustedDisplayRate
      }
      const query = {
        currency: this.currency,
        searchId: this.searchId,
        sourceMarket: this.sourceMarket || this.$route.query.sourceMarket
      }
      poll(
        () => {
          return this.$store.dispatch('bookingPolicy/checkout', {
            body,
            query,
            refundAble: this.refundAble
          })
        },
        2e3,
        20
      ).then((data) => {
        const bookingPolicyCancellation = data.outlets.availability.package.cancellationPolicy.freeCancellationBefore ? data.outlets.availability.package.cancellationPolicy.freeCancellationBefore.substring(0, 10) : null
        const selectedPckgCancellation = this.selectedPckg.cancellationPolicy.freeCancellationBefore ? this.selectedPckg.cancellationPolicy.freeCancellationBefore.substring(0, 10) : null
        if (bookingPolicyCancellation !== selectedPckgCancellation) {
          const { search } = this.$store.state.searchOne.availability
          this.$gtm.trackEvent({
            event: 'inconsistent_cancellation_policy',
            label: `/property?${Object.keys(this.$route.query).map(key => `${key}=${this.$route.query[key]}`).join('&')}`,
            value: this.selectedPckg.propertyCode,
            propertyCode: this.selectedPckg.propertyCode,
            adultCount: search.adult_count,
            children: search.children,
            roomCount: search.room_count,
            checkInDate: search.check_in_date,
            checkOutDate: search.check_out_date,
            sourceMarket: search.source_market,
            currency: search.currency
          })
        }
        this.isLoading = this.$store.state.bookingPolicy.retrying
      }).catch(_ => {
        this.isLoading = this.$store.state.bookingPolicy.retrying
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/bulma-variables';

  .booking-policy-popup--spiner {
    padding: 100px 40px;
  }

  .booking-policy-modal .pp-modal--content {
    @include tablet {
      min-width: 640px;
    }
  }
  .booking-policy-modal .content ul {
    margin-bottom: 1em;
  }
</style>
