<template>
  <path d="M1 8.59202H22.17L17.29 13.472C16.9 13.862 16.9 14.502 17.29 14.892C17.68 15.282 18.31 15.282 18.7 14.892L25.29 8.30202C25.3827 8.20951 25.4562 8.09962 25.5064 7.97864C25.5566 7.85767 25.5824 7.72799 25.5824 7.59702C25.5824 7.46605 25.5566 7.33637 25.5064 7.2154C25.4562 7.09442 25.3827 6.98453 25.29 6.89202L18.71 0.29202C18.6174 0.199438 18.5075 0.125998 18.3865 0.0758934C18.2656 0.0257884 18.1359 1.95102e-09 18.005 0C17.8741 -1.95102e-09 17.7444 0.0257884 17.6235 0.0758934C17.5025 0.125998 17.3926 0.199438 17.3 0.29202C17.2074 0.384602 17.134 0.494513 17.0839 0.615477C17.0338 0.736441 17.008 0.86609 17.008 0.997021C17.008 1.12795 17.0338 1.2576 17.0839 1.37856C17.134 1.49953 17.2074 1.60944 17.3 1.70202L22.17 6.59202H1C0.45 6.59202 0 7.04202 0 7.59202C0 8.14202 0.45 8.59202 1 8.59202Z" />
</template>

<script>
export default {
  name: 'IconArrowRightLong'
}
</script>

<style scoped>

</style>
