<template>
  <p
    v-if="freeCancellationBefore"
    class="has-text-base is-flex"
    style="align-items: center;">
    <i class="ap-icon-check icon-size has-text-success  m-r-2x" />&nbsp;
    <span>{{ $t('components.CancellationParse.free') }}</span>&nbsp;
    <span>{{ freeCancellationBefore | dateTz({ tz: $store.state.app.timezone, isBeforeMidnight: true }) }} </span>
  </p>
  <p
    v-else
    class="is-flex"
    style="align-items: center;">
    <span
      class="icon-size m-r-2x has-text-danger"
      style="font-weight: 900;">!</span>
    <span>
      <span class="has-text-danger">This rate is non-refundable.</span>&nbsp;
      <span :class="{'has-text-base': hideTooltip, 'has-text-danger': !hideTooltip}">No refunds, date changes or name changes possible.</span>
      <el-tooltip
        v-if="!hideTooltip"
        placement="bottom">
        <div slot="content">
          For flexible rates please go back and use the room filters at the top of the page.
        </div>
        <i class="el-icon-info has-text-secondary" />
      </el-tooltip>
    </span>
  </p>
</template>

<script>
export default {
  name: 'CancellationParse',
  props: {
    freeCancellationBefore: {
      type: String,
      required: true,
      default: undefined
    },
    hideTooltip: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-size {
    font-size: 1.25em;
  }
</style>
